<template>
  <v-container>
    <v-alert v-if="!anno" type="warning">
      Attenzione! È necessario selezionare un anno accademico.
    </v-alert>
    <div v-if="anno">
      <BreadcrumbComponent :breadcrumbs="breadcrumbs" />
      <v-row>
        <v-col cols="8">
          <h1>Rendiconti assegnati</h1>
          <p>Lista dei rendiconti assegnati dal Responsabile dei controlli.</p>
        </v-col>
        <v-col cols="4">
          <RiquadroProfiloRuolo />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <div v-if="loadDichiarazione">
        <v-row class="mt-8">
          <v-col cols="8">
            <h2>Dichiarazione di assenza di conflitto di interessi</h2>
          </v-col>
          <v-col cols="2">
            <a @click="download()">
              Scarica dichiarazione
              <v-icon> mdi-tray-arrow-down </v-icon>
            </a>
          </v-col>
          <v-col cols="2">
            <v-file-input
              v-model="file"
              accept="pdf/*"
              label="Carica"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row class="mb-8">
          <v-col cols="8"
            ><p>Carica dichiarazione di assenza di conflitti di interessi</p>
          </v-col>
          <v-col cols="2" v-if="!dichiarazione">
            <v-chip class="ma-2" color="warning"> Da caricare </v-chip>
          </v-col>
          <v-col cols="2" v-else>
            <v-chip class="ma-2" color="success"> Caricata </v-chip>
          </v-col>
          <v-col cols="1">
            <v-btn
              v-if="file"
              :loading="loader"
              color="primary"
              class="ma-2 white--text"
              @click="uploadFile()"
            >
              Upload
              <v-icon right dark> mdi-cloud-upload </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1" v-if="dichiarazione">
            <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="
                downloadFile(
                  dichiarazione.orientamentoFile.id,
                  dichiarazione.orientamentoFile.nomeOriginale
                )
              "
            >
              Download
              <v-icon right dark> mdi-cloud-download </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <v-alert v-if="!dichiarazioneCaricata" type="warning">
        Attenzione! È necessario caricare la dichiarazione assenza di conflitti
        di interesse per accettare incarichi.
      </v-alert>
      <v-tabs v-model="tab">
        <v-tab> Incarichi da accettare </v-tab>
        <v-tab>Incarichi Accettati</v-tab>
        <v-tab-item>
          <v-row class="my-4">
            <v-col col="8">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per N° rendiconto"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headersDaAcc"
                :items="assegnazioniDaAccettare"
                v-model="assegnazioniSelezionate"
                :show-select="dichiarazioneCaricata"
                :search="ricerca"
                :items-per-page="10"
                class="elevation-1"
                :loading="loadingRendiconti"
                loading-text="Caricamento dati in corso..."
                no-data-text="Nessun rendiconto disponibile"
                ><!-- eslint-disable -->
                <template v-slot:item.incaricato="{ item }">
                  <span>{{item.incaricato.anagrafica.cognome}} {{item.incaricato.anagrafica.nome}}</span>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex flex-row-reverse">
              <v-btn
                v-if="dichiarazioneCaricata"
                class="primary"
                :disabled="!assegnazioniSelezionate"
                @click="dialogSalva = true"
              >
                Accetta incarico
              </v-btn>
            </v-col>
          </v-row>
          <dialog-conferma
            @callback="accettaIncarichi()"
            :dialog.sync="dialogSalva"
            @close-dialog="dialogSalva = false"
          />
          <v-snackbar
            v-model="snackbar"
            :timeout="3000"
            shaped
            :color="snackbarColor"
          >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </v-tab-item>

        <!-- Seconda tab -->
        <v-tab-item>
          <v-row class="my-4">
            <v-col col="8">
              <v-text-field
                solo
                v-model="ricerca"
                append-icon="mdi-magnify"
                label="Cerca per N° rendiconto"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headersAccettati"
                :items="assegnazioniAccettate"
                :search="ricerca"
                :items-per-page="10"
                class="elevation-1"
                :loading="loadingRendiconti"
                loading-text="Caricamento dati in corso..."
                no-data-text="Nessun rendiconto disponibile"
                ><!-- eslint-disable -->
                <template v-slot:item.dataAccettazione="{ item }">
                  <span>{{item.dataAccettazione | formatDate}}</span>
                </template>
                <!-- eslint-enable -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <div class="mt-6">
        <TornaIndietroComponent text="Torna a Controlli" />
      </div>
    </div>
  </v-container>
</template>

<script>
import BreadcrumbComponent from "@/components/Common/BreadcrumbComponent";
import { mapGetters, mapState } from "vuex";
import RiquadroProfiloRuolo from "@/components/Controlli/RiquadroProfiloRuolo";
import TornaIndietroComponent from "@/components/TornaIndietroComponent";
import ControlliRepository from "@/api/ministero/ControlliRepository";
import DialogConferma from "@/components/DialogConferma";
import MinisteroRepository from "@/api/ministero/MinisteroRepository";

export default {
  name: "RendicontiAssegnatiView",
  data: () => ({
    dichiarazioneCaricata: false,
    loadDichiarazione: false,
    loadingPage: false,
    loader: false,
    file: null,
    dichiarazione: null,
    tab: null,
    dialogSalva: null,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    loadingRendiconti: false,
    assegnazioniSelezionate: [],
    assegnazioni: [],
    assegnazioniAccettate: [],
    assegnazioniDaAccettare: [],
    ricerca: "",
    headersDaAcc: [
      {
        text: "ID rendiconto",
        align: "start",
        value: "rendiconto.id",
      },
      {
        text: "Soggetto attuatore",
        value: "rendiconto.programma.nomeIstituzione",
      },
      { text: "CUP", value: "rendiconto.programma.cup" },
      { text: "Soggetto incaricato", value: "incaricato" },
    ],
    headersAccettati: [
      {
        text: "ID rendiconto",
        align: "start",
        value: "rendiconto.id",
      },
      {
        text: "Soggetto attuatore",
        value: "rendiconto.programma.nomeIstituzione",
      },
      { text: "CUP", value: "rendiconto.programma.cup" },
      { text: "Data accettazione", value: "dataAccettazione" },
    ],
  }),
  components: {
    TornaIndietroComponent,
    RiquadroProfiloRuolo,
    BreadcrumbComponent,
    DialogConferma,
  },
  computed: {
    breadcrumbs: function () {
      {
        return [
          {
            text: "Scrivania",
            disabled: false,
            to: `/scrivania`,
            exact: true,
            link: true,
          },
          {
            text: "Controlli",
            disabled: false,
            to: `/scrivania/controlli`,
            exact: true,
            link: true,
          },
          {
            text: "Incarichi assegnati",
            disabled: true,
            to: `/scrivania/controlli/rendiconti-assegnati`,
            exact: true,
            link: true,
          },
        ];
      }
    },
    ...mapState(["idStrutture", "anno", "user"]),
    ...mapGetters([
      "isRoleIncaricatoControllo",
      "isRoleResponsabileControllo",
      "isRoleMinistero",
      "isRoleConsulenza",
    ]),
  },
  created() {
    this.initialize();
  },
  watch: {
    anno() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      if (this.anno) {
        this.getDichiarazione(this.anno);
        if (this.isRoleConsulenza) {
          this.getAssegnazioni(this.anno);
        }
        if (this.isRoleIncaricatoControllo) {
          this.getAssegnazioniIncaricato(this.anno);
        }
      }
    },
    async getDichiarazione(anno) {
      this.loadingPage = true;
      this.dichiarazione =
        await ControlliRepository.getDichiarazioneAssenzaConflitto(anno);
      if (this.dichiarazione) {
        this.dichiarazioneCaricata = true;
      }
      this.loadDichiarazione = true;
      this.loadingPage = false;
    },
    async getAssegnazioni(anno) {
      this.loadingRendiconti = true;
      this.assegnazioni = await ControlliRepository.getAssegnazioniIncarico(
        anno
      );
      //lista assegnazioni accettate -> solo per consulenza
      this.assegnazioniAccettate = this.assegnazioni.filter((element) => {
        if (
          element.statoAssegnazione &&
          element.statoAssegnazione.id === 1 &&
          element.dataAccettazione
        )
          return element;
      });
      //lista assegnazioni che devono ancora essere accettate -> solo per consulenza
      this.assegnazioniDaAccettare = this.assegnazioni.filter((element) => {
        if (!element.statoAssegnazione && !element.dataAccettazione)
          return element;
      });
      this.loadingRendiconti = false;
    },
    async getAssegnazioniIncaricato(anno) {
      this.loadingRendiconti = true;
      this.assegnazioni = await ControlliRepository.getAssegnazioniIncarico(
        anno
      );
      //lista assegnazione dell'utente che sono state accettate
      this.assegnazioniAccettate = this.assegnazioni.filter((element) => {
        if (
          element.statoAssegnazione &&
          element.statoAssegnazione.id === 1 &&
          element.dataAccettazione
        )
          if (element.incaricato.id === this.user.id) {
            return element;
          }
      });

      //lista assegnazione dell'utente che devono ancora essere accettate
      this.assegnazioniDaAccettare = this.assegnazioni.filter((element) => {
        if (!element.statoAssegnazione && !element.dataAccettazione)
          if (element.incaricato.id === this.user.id) {
            return element;
          }
      });

      this.loadingRendiconti = false;
    },
    async accettaIncarichi() {
      let assegnazioniSelezionate = { ...this.assegnazioniSelezionate };
      const data = new FormData();
      data.append(
        "assegnazioniSelezionate",
        JSON.stringify(assegnazioniSelezionate)
      );
      try {
        await ControlliRepository.accettaIncarico(this.anno, data);
        this.snackbarText =
          "Le assegnazioni sono state accettate correttamente";
        this.snackbarColor = "success";
        this.snackbar = true;
        this.initialize();
      } catch (e) {
        console.log(e);
        this.snackbarText =
          "Problema durante l'accettazione dell'incarico, contattare il supporto se il problema persiste";
        this.snackbarColor = "red accent-2";
        this.snackbar = true;
      }
    },
    async download() {
      await MinisteroRepository.downloadDichiarazioneConflittoInteressi(
        this.anno
      );
    },
    async uploadFile() {
      if (this.file) {
        this.loader = true;
        const data = new FormData();
        data.append("file", this.file);
        try {
          await ControlliRepository.uploadDichiarazione(this.anno, data);
          this.loader = false;
          this.snackbarText = "Dichiarazione caricata con successo";
          this.snackbarColor = "success";
          this.snackbar = true;
          this.initialize();
        } catch (e) {
          console.log(e);
          this.snackbarText =
            "Problema durante l'upload del file, contattare il supporto se il problema persiste";
          this.snackbarColor = "red accent-2";
          this.snackbar = true;
          this.loader = false;
        }
      }
    },
    async downloadFile(idFile, nomeOriginale) {
      await MinisteroRepository.downloadFile(this.anno, idFile, nomeOriginale);
    },
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./RendicontiAssegnatiView.vue?vue&type=template&id=225e5618&scoped=true&"
import script from "./RendicontiAssegnatiView.vue?vue&type=script&lang=js&"
export * from "./RendicontiAssegnatiView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225e5618",
  null
  
)

export default component.exports